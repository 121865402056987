.icon {
  transition: transform 0.1s ease-in;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  font-size: 0;

  &.is-rotated {
    transform: rotate(180deg);
  }

  &.icon-primary-hover {
    cursor: pointer;

    &:hover, &.is-icon-active {
      svg {
        path {
          fill: var(--color-black);
        }

        circle {
          stroke: var(--color-black);
        }

        rect {
          fill: var(--color-black);
        }
      }
    }
  }

  &.icon-primary-hover-custom {
    cursor: pointer;

    &:hover, &.is-icon-active {
      svg {
        .hover-fill {
          fill: var(--color-black);
        }

        .hover-stroke {
          stroke: var(--color-black);
        }
      }
    }
  }

  &.icon-primary-dark-hover {
    cursor: pointer;

    &:hover, &.is-icon-active {
      svg {
        path {
          fill: var(--color-primary-dark);
        }

        circle {
          stroke: var(--color-primary-dark);
        }
      }
    }
  }

  &.icon-primary-dark-custom-hover {
    cursor: pointer;

    &:hover, &.is-icon-active {
      svg {
        .hover-fill {
          fill: var(--color-primary-dark);
        }

        .hover-stroke {
          stroke: var(--color-primary-dark);
        }
      }
    }
  }
}

@mixin icon-px($width, $height) {
  .icon-#{$width}x#{$height}px {
    width: #{$width}px;
    height: #{$height}px;
    overflow: hidden;
  }
}

@include icon-px(16, 16);
@include icon-px(7, 11);
@include icon-px(28, 28);
@include icon-px(6, 10);
@include icon-px(12, 10);
@include icon-px(16, 12);
@include icon-px(14, 16);
@include icon-px(14, 17);
@include icon-px(9, 10);
@include icon-px(9, 5);
@include icon-px(9, 16);
@include icon-px(11, 7);
@include icon-px(20, 20);
@include icon-px(21, 19);
@include icon-px(24, 20);
@include icon-px(24, 24);
@include icon-px(18, 17);
@include icon-px(18, 18);
@include icon-px(18, 20);
@include icon-px(18, 19);
@include icon-px(23, 23);
@include icon-px(80, 80);
@include icon-px(18, 18);
@include icon-px(18, 2);
@include icon-px(18, 16);
@include icon-px(26, 26);
@include icon-px(80, 71);
@include icon-px(62, 15);
@include icon-px(24, 14);
@include icon-px(12, 12);
@include icon-px(58, 58);
