// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.core();

$black-87-opacity: rgba(black, 0.87);

$interid-primary: (
  50: var(--color-primary-light),
  100: var(--color-primary-light),
  200: var(--color-primary-light),
  300: var(--color-primary-light),
  400: var(--color-primary-light),
  500: var(--color-primary),
  600: var(--color-primary),
  700: var(--color-primary-dark),
  800: var(--color-primary-dark),
  900: var(--color-primary),
  A100: var(--color-primary-light),
  A200: var(--color-primary-light),
  A400: var(--color-primary),
  A700: var(--color-primary),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  )
);

$interid-accent: (
  50: var(--color-accent-light),
  100: var(--color-accent-light),
  200: var(--color-accent-light),
  300: var(--color-accent-light),
  400: var(--color-accent),
  500: var(--color-accent),
  600: var(--color-accent),
  700: var(--color-accent-dark),
  800: var(--color-accent-dark),
  900: var(--color-accent-dark),
  A100: var(--color-primary-light),
  A200: var(--color-accent),
  A400: var(--color-accent),
  A700: var(--color-accent-dark),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  )
);

$interid-warn: (
  50: var(--color-warn-light),
  100: var(--color-warn-light),
  200: var(--color-warn-light),
  300: var(--color-warn-light),
  400: var(--color-warn),
  500: var(--color-warn),
  600: var(--color-warn),
  700: var(--color-dark),
  800: var(--color-dark),
  900: var(--color-dark),
  A100: var(--color-warn-light),
  A200: var(--color-warn-light),
  A400: var(--color-warn),
  A700: var(--color-dark-dark),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  )
);

$interid-app-primary: mat.define-palette($interid-primary);
$interid-app-accent:  mat.define-palette($interid-accent, A200, A100, A400);
$interid-app-warn:    mat.define-palette($interid-warn);

$interid-app-theme: mat.define-light-theme($interid-app-primary, $interid-app-accent, $interid-app-warn);

@include mat.all-component-themes($interid-app-theme);

mat-form-field {
  width: 100%;
}

mat-card-footer {
  margin: 15px 0;
}

.mat-table {
  width: 100%;
}

.mat-dialog-container {
  overflow: hidden!important;
}

mat-tab-group.mat-tab-group-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .mat-tab-body-wrapper {
    flex: 1;
  }
}

.mat-dialog-actions {
  padding-bottom: 20px!important;
}

.mat-tooltip {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 140% !important;
  color: #FFFFFF !important;
  background-color: #000000 !important;
  border-radius: 8px !important;
  padding: 3px 16px !important;
}

.mat-tab-group-modals {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .mat-tab-header {
    flex-grow: 0;
  }

  .mat-tab-body-wrapper {
    flex: 1;
  }
}

.mat-tab-label {
  min-width: 60px!important;
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}

.mat-dialog-content {
  max-height: none!important;
}

.cdk-transparent-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  background: rgba(255, 255, 255, 0.2);
}

.mat-paginator {
  background: none;
}

.mat-raised-button {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 5px 0px rgba(0, 0, 0, 0.06)
}

.mat-tab-body-content {
  overflow-x: hidden!important;
}

mat-button-toggle-group {
  .mat-button-toggle {
    font-size: 14px;
    color: var(--color-black);
  }

  .mat-button-toggle-checked {
    color: var(--color-primary-light);
    background: var(--color-primary);
  }
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  .mat-snack-bar-handset {
    margin-top: 45px!important;
  }
}
