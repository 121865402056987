.ws-input-text-field__floating {
  position: relative;

  .ws-text-field__input {
    height: calc(2.5rem + 2px);
    padding: 0.5rem 0.75rem;
  }

  .ws-text-field__label {
    position: absolute;
    top: 50%;
    left: 0.3125rem;
    display: flex;
    transform: translateY(-50%);
    pointer-events: none;
    border: none;
    background-color: #fff;
    color: var(--unicolor-control-accent, #007AFF);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: top 0.1s ease-in-out, scale 0.1s ease-in-out;
  }

  .ws-text-field__input::-moz-placeholder {
    color: transparent;
  }

  .ws-text-field__input::placeholder {
    color: transparent;
  }

  .ws-text-field__input:focus~.ws-text-field__label,
  .ws-text-field__input:not(:placeholder-shown)~.ws-text-field__label {
    top: 0;
    transform: translateY(-50%) scale(0.75);
  }
}

.ws-input-text-field {
  input[type="text"] {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  .ws-text-field__label {
    display: block;
    margin-bottom: 0.25rem;
  }

  .ws-text-field__input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    color: rgb(33, 37, 41);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgb(234, 234, 234);
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
      color: #212529;
      opacity: 0.4;
    }

    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: var(--unicolor-control-accent, #007AFF);
      outline: 0;
      box-shadow: 0 0 0 0.07rem rgba(158, 158, 158, 0.2);
    }

    &:disabled, &[readonly] {
      background-color: #f5f5f5;
    }

    &:disabled {
      color: rgba(33, 37, 41, .28);
    }
  }

  .ws-text-field__input[type="search"]::-webkit-search-decoration,
  .ws-text-field__input[type="search"]::-webkit-search-cancel-button,
  .ws-text-field__input[type="search"]::-webkit-search-results-button,
  .ws-text-field__input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}