.__app-content {
    a {
        display: inline-block;
        color: var(--color-primary);

        &:hover {
            color: var(--color-primary-dark);
        }
    }

    figure.image {
        margin-left: 0;
        margin-right: 0;

        img {
            max-width: 100%;
        }
    }

    .text-tiny {
        font-size: 10px;
    }

    .text-small {
        font-size: 12px;
    }

    .text-default {
        font-size: 14px;
    }

    .text-big {
        font-size: 18px;
    }

    .text-huge {
        font-size: 20px;
    }

    .text-huge-xl {
        font-size: 24px;
    }

    .text-huge-xxl {
        font-size: 28px;
    }

    ul + h1 {
        margin-top: 24px;
    }

      
    hr {
        border-color: var(--color-gray) !important;
    }

    p + ul {
        margin-top: 10px;
    }

    p + ol {
        margin-top: 10px;
    }

    p + h3,
    ul + h3,
    ol + h3 {
        margin-top: 40px;
    }

    figure.image {
        background: none;

        figcaption {
            background: none;
            font-size: 15px;
            font-style: italic;
            line-height: 24px;
            color: var(--color-black);
            margin-bottom: 24px;
            letter-spacing: 0.1px;
            text-align: center;
        }
    }

    figure.table {
        border: none;
        width: 100%;
    }

    table {
        width: 100%;
        border: none !important;

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: var(--color-primary-light);
                }

                &:nth-child(even) {
                    background-color: var(--color-white);
                }
            }

            td {
                padding: 10px;

                span {
                    font-size: 14px;
                    color: var(--color-black);
                }

                &:last-child {
                    border-right: 0;
                }
            }
        }
    }
}

.__app-content:not(.is-article) {

  h1,
  h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: var(--color-primary-dark);
      margin-bottom: 23px;
  }

  h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: var(--color-black);
      margin-bottom: 20px;
  }

  h4 {
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;
      color: var(--color-black);
      margin-bottom: 15px;
  }

  p {
      font-size: 15px;
      line-height: 24px;
      color: var(--color-black);
      margin-bottom: 24px;
      letter-spacing: 0.1px;
  }

    ul {
        list-style: none;
        padding-left: 6px;
        margin-bottom: 24px;

        li {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: '•';
                color: var(--color-primary);
                margin-right: 11px;
                display: inline-block;
            }
        }
    }

    ol {
        padding-left: 22px;

        li {
            padding-left: 6px;
            margin-bottom: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;
            color: var(--color-black);
            letter-spacing: 0.1px;
        }
    }

    blockquote {
        border: none !important;
        background-color: var(--color-primary-light);
        color: var(--color-black);
        padding-left: 24px !important;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.__app-content.is-article {

  h1,
  h2 {
      color: #253238;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 23px;

      @media only screen and (min-width: 768px) {
          font-size: 36px;
      }

      @media only screen and (min-width: 1280px) {
          font-size: 40px;
      }
  }

  h3 {
      color: #253238;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 20px;

      @media only screen and (min-width: 768px) {
          font-size: 28px;
      }

      @media only screen and (min-width: 1280px) {
          font-size: 32px;
      }
  }

  h4 {
      color: #253238;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 15px;

      @media only screen and (min-width: 768px) {
          font-size: 20px;
      }

      @media only screen and (min-width: 1280px) {
          font-size: 24px;
      }
  }

  p {
      color: #253238;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      @media only screen and (min-width: 768px) {
          font-size: 18px;
      }

      @media only screen and (min-width: 1280px) {
          font-size: 20px;
      }
  }

    ol {
        list-style: none;
        margin-bottom: 24px;
        counter-reset: item;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        @media only screen and (min-width: 768px) {
            gap: 16px;
        }

        li {
            color: #253238;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            counter-increment: item;

            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (min-width: 768px) {
                font-size: 18px;
            }

            @media only screen and (min-width: 1280px) {
                font-size: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: counter(item);
                color: var(--color-primary);
                margin-right: 16px;
                display: inline-block;
                font-weight: 700;
            }
        }
    }

    ul {
        list-style: none;
        margin-bottom: 24px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        @media only screen and (min-width: 768px) {
            gap: 16px;
        }

        li {
            color: #253238;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;

            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (min-width: 768px) {
                font-size: 18px;
            }

            @media only screen and (min-width: 1280px) {
                font-size: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: url(/assets/images/Ellipse_187.svg);
                color: var(--color-primary);
                margin-right: 16px;
                display: inline-block;
            }
        }
    }

    blockquote {
        border-radius: 24px;
        background: #ecf0f2;
        margin-bottom: 24px;

        @media only screen and (min-width: 768px) {
            margin-bottom: 32px;
        }

        p {
            display: flex;
            align-items: flex-start;
            margin-bottom: 0 !important;
            margin-top: 0 !important;

            font-weight: 700 !important;

            padding: 16px;
            gap: 12px;

            @media only screen and (min-width: 480px) {
                padding: 24px;
            }

            @media only screen and (min-width: 768px) {
                gap: 32px;
                padding: 32px;
            }
        }

        p::before {
            content: url(/assets/images/mingcute_quote-right-fill.svg);
            transform: scale(0.5);
            width: 24px;
            height: 24px;
            margin-top: -5px;

            @media only screen and (min-width: 768px) {
                transform: scale(1);
                width: 48px;
                height: 48px;
            }
        }

        p::after {
            content: url(/assets/images/mingcute_quote-right-fill.svg);
            display: inline-block;
            width: 24px;
            height: 24px;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scale(0.5) rotate(180deg);
            margin-top: 5px;

            @media only screen and (min-width: 768px) {
                transform: scale(1) rotate(180deg);
                width: 48px;
                height: 48px;
                margin-top: -5px;
            }
        }
    }
}
