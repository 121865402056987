.__app-message-success {
    border-radius: 8px !important;
    background: #ecf0f2;
    padding: 22px 15px 22px 35px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.__app-message-error {
    border-radius: 8px !important;
    background: #ecf0f2;
    padding: 22px 15px 22px 35px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.__app-message-info {
    border-radius: 8px !important;
    background: #ecf0f2;
    padding: 22px 15px 22px 35px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.__app-message-warning {
    border-radius: 8px !important;
    background: #ecf0f2;
    padding: 22px 15px 22px 35px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
