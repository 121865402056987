.__app-fluid-container {
  width: 100vw;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 80px;
}

.app--layout-wide {
  .__app-fluid-container-desktop-padding {
    padding-left: 80px;
    padding-right: 80px;
  }

  .__app-fluid-container-padding-bottom {
    padding-bottom: 80px;
  }

  .__app-fluid-container-margin-bottom {
    margin-bottom: 80px;
  }

  .__app-fluid-container-control-padding-bottom {
    padding-bottom: 24px;
  }

  .__app-fluid-container-control-margin-bottom {
    margin-bottom: 24px;
  }

  .__app-fluid-container-control-margin-right {
    margin-right: 48px;
  }

  .__app-fluid-container-catalog-menu-margin-right {
    margin-right: 28px;
  }

  .__app-fluid-container {
    padding: 0 80px;
  }

  .__app-fluid-negative {
    padding: 0 -80px;
  }
}

.app--layout-desktop {
  .__app-fluid-container-desktop-padding {
    padding-left: 24px;
    padding-right: 24px;
  }

  .__app-fluid-container-padding-bottom {
    padding-bottom: 64px;
  }

  .__app-fluid-container-margin-bottom {
    margin-bottom: 64px;
  }

  .__app-fluid-container-control-padding-bottom {
    padding-bottom: 24px;
  }

  .__app-fluid-container-control-margin-bottom {
    margin-bottom: 24px;
  }

  .__app-fluid-container-control-margin-right {
    margin-right: 40px;
  }

  .__app-fluid-container-catalog-menu-margin-right {
    margin-right: 28px;
  }

  .__app-fluid-container {
    padding: 0 24px;
  }

  .__app-fluid-container-negative {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.app--layout-tablet { 
  .__app-fluid-container-tablet-padding {
    padding-left: 24px;
    padding-right: 24px;
  }

  .__app-fluid-container-padding-bottom {
    padding-bottom: 48px;
  }

  .__app-fluid-container-margin-bottom {
    margin-bottom: 48px;
  }

  .__app-fluid-container-control-padding-bottom {
    padding-bottom: 16px;
  }

  .__app-fluid-container-control-margin-bottom {
    margin-bottom: 16px;
  }

  .__app-fluid-container {
    padding: 0 24px;
  }

  .__app-fluid-container-negative {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.app--layout-mobile {
  .__app-fluid-container-mobile-padding {
    padding-left: 16px;
    padding-right: 16px;
  }

  .__app-fluid-container-padding-bottom {
    padding-bottom: 40px;
  }

  .__app-fluid-container-margin-bottom {
    margin-bottom: 40px;
  }

  .__app-fluid-container-control-padding-bottom {
    padding-bottom: 16px;
  }

  .__app-fluid-container-control-margin-bottom {
    margin-bottom: 16px;
  }

  .__app-fluid-container {
    padding: 0 16px;

    &.__mobile-disable-side-padding {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .__app-fluid-container-negative {
    margin-left: -16px;
    margin-right: -16px;

    &.__mobile-disable-side-padding {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}