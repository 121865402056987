@import './reset';

@import 'theme';

@import 'material/material-theme';

@import 'components/fluid-container';
@import 'components/app-modal';
@import 'components/app-overlay';
@import 'components/link-button';
@import 'components/city-selector';
@import 'components/messages';
@import 'components/content';
@import 'components/mat-tab-a';
@import 'components/icon';

@import 'mixins/animations/color-transition.mixin';
@import 'mixins/animations/background-transition.mixin';
@import 'mixins/animations/list-toogle-transition.mixin';
@import 'mixins/animations/rotate-list-toogle-trigger-transition.mixin';

@import 'components/input-text-field.scss';

* {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

ul,
ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

a {
    display: block;
    text-decoration: none;
}

.c-dealer-title {
    display: inline;

    img {
        width: 100%;
        max-width: 100%;
        padding-top: 24px;
    }
}

.c-frame-1914 > span > p > span {
    color: #253238;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @media only screen and (min-width: 1000px) {
        font-size: 18px !important;
    }
}

.c-sider-frame-2 > span * {
    display: inline;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #ffffff;

    a {
        display: inline !important;
        text-decoration: underline;
    }
}

.c-bottom-slider > span * {
    display: inline;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #253238;

    a {
        display: inline !important;
        text-decoration: underline;
    }
}

button {
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    outline: none;
    cursor: pointer;
}

input {
    border: 0;
    background: transparent;
    width: 100%;

    &:focus {
        outline: none;
    }
}

strong {
    font-weight: 500;
}

p {
    margin-bottom: 24px;

    @media only screen and (min-width: 768px) {
        margin-bottom: 32px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

span {
    @include color-transition;
    @include background-transition;
}

svg path {
    transition: fill 0.1s ease-in;
}

svg circle {
    transition: stroke 0.1s ease-in;
}

.app--layout-mobile {
    .__hide-app-mobile {
        display: none !important;
    }
}

.app--layout-tablet {
    .__hide-app-tablet {
        display: none !important;
    }
}

.app--layout-desktop {
    .__hide-app-desktop {
        display: none !important;
    }
}

.app--layout-wide {
    .__hide-app-wide {
        display: none !important;
    }
}

.___hidden {
    display: none;
}

.cdk-global-scrollblock {
    position: static !important;
    width: initial !important;
    overflow-y: inherit !important;
}

.___no-wrap {
    word-break: keep-all;
    white-space: nowrap;
}

.___hide-mobile-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none;
    }
}

.___disable-user-select {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

img {
    border: none;
}

img[src=''],
img:not([src]) {
    opacity: 0;
}

.mat-dialog-container {
    overflow-y: auto !important;
}

//hide captcha for mobile
@media (max-width: 426px) {
    .grecaptcha-badge {
        visibility: hidden;
    }
}
