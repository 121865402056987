.__app-mat-tab {
  .mat-tab-label {
    opacity: 1;
    min-width: auto!important;
    padding-left: 0!important;;
    padding-right: 0!important;
    margin-right: 38px;

    &:last-child {
      margin-right: 0;
    }

    .mat-tab-label-content {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: var(--color-blue-gray-dark);
      transition: font-size 0.2s ease-in;
    }
  }

  .mat-tab-label-active {
    opacity: 1;

    .mat-tab-label-content {
      color: var(--color-primary-dark);
    }
  }

  .mat-ink-bar {
    background-color: var(--color-primary)!important;
  }

  .mat-tab-header {
    border-bottom: 1px solid var(--color-border-light-blue);
  }
}

.app--layout-mobile {
  .__app-mat-tab {
    .mat-tab-label {
      margin-right: 33px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
