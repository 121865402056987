@import '../../../libs/interid-site-web/ui-styles/src/lib/index';
@import "keen-slider/keen-slider.min.css";

.__interid_initial_loader {
    display: none;
    height: 3px;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: 999;
    top: 0;
    animation: __interid-initial-loader-appear 2s linear;
}

.__interid_initial_loader:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: var(--color-primary);
    animation: __interid-initial-loading 2s linear infinite;
}

@keyframes __interid-initial-loading {
    from {left: 0; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}

@keyframes __interid-initial-loader-appear {
    from {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
