@import "../mixins/animations/color-transition.mixin";

.__app-link-button {
  display: block;
  border: 0; padding: 0;
  cursor: pointer;
  background-color: transparent;

  &.__app-link-button-bold {
    span {
      font-weight: 500;
    }
  }

  &.__app-link-button-selected {
    span {
      color: var(--color-primary);
    }
  }

  span {
    @include color-transition;
    color: var(--color-black);
    font-size: 14px;
    line-height: 16px;
  }

  &:hover {
    span {
      color: var(--color-primary);
    }
  }

  &:disabled {
    cursor: not-allowed;

    span {
      color: var(--color-primary-disabled);
    }

    &:hover {
      span {
        color: var(--color-primary-disabled);
      }
    }
  }
}
