@import "../mixins/animations/color-transition.mixin";

.__app-city-selector {
  display: flex;
  cursor: pointer;
  user-select: none;

  &:hover {
    >.title {
      span {
        color: var(--color-primary);
      }
    }
  }

  >.icon {
    width: 16px;
    height: 16px;
    font-size: 0;
    overflow: hidden; 

    svg {
      font-size: 0;
    }
  }

  >.title {
    span {
      @include color-transition;
      font-size: 14px;
      line-height: 15px;
      color: var(--color-graphite8);
      margin-left: 3px;
      border-bottom: 1px dashed;
      text-decoration: none;
    }
  }
}
