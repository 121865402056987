.__app-overlay {
    padding: 0;
    max-width: 80vw;
    user-select: none;
    background: var(--color-white);
    box-shadow: 0 0 15px rgba(3, 27, 78, 0.15);
    border: 1px solid var(--color-border-light-blue);
    border-radius: 3px;
}

.__app-city-overlay {
    padding: 0;
    max-width: 80vw;
    user-select: none;
    background: var(--color-white);
    box-shadow: 0 0 15px rgba(3, 27, 78, 0.15);
    border-radius: 8px;
}

.__app-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    background: rgba(255, 255, 255, 0);
}
