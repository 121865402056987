.__app-modal {
    padding: 0;
    max-width: 80vw;
    user-select: none;

    .mat-dialog-container {
        padding: 0 !important;
        background: #ffffff;
        border: 1px solid #ecf0f2;
        box-shadow: 4px 0px 16px rgba(26, 26, 26, 0.08);
        border-radius: 24px;
        position: relative;
    }

    .mat-dialog-content {
        overflow: hidden !important;
        height: 100%;
    }
}

.__app-search-popup-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    background: rgba(37, 50, 56, 0.3);
}

.__app-modal-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    background: rgba(255, 255, 255, 0.5);
}

.__app-modal-ui-select {
    .mat-dialog-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        background: #ffffff;
        border-radius: 16px 16px 0px 0px;
    }
}

.__app-modal-product-review {
    padding: 0;
    max-width: 80vw;
    user-select: none;

    .mat-dialog-container {
        padding: 0 !important;
        background: #ffffff;
        border: 1px solid #ecf0f2;
        box-shadow: 4px 0px 16px rgba(26, 26, 26, 0.08);
        border-radius: 24px;
        position: relative;
    }
}

.__app-modal-product-question {
    padding: 0; 
    user-select: none;

    .mat-dialog-container {
        padding: 0 !important;
        background: #ffffff;
        border: 1px solid #ecf0f2;
        box-shadow: 4px 0px 16px rgba(26, 26, 26, 0.08);
        border-radius: 24px;
        position: relative;
    }
}

@media (max-width: 768px) {
    .__app-modal-product-review {
        max-width: 100vw !important;
        width: 100% !important;

        .__app-product-review-modal-component {
            padding: 16px;
        }
    }
}

@media (min-width: 769px) {
    .__app-modal-product-review {
        width: 800px !important;
        max-width: 80vw !important;

        .__app-product-review-modal-component {
            padding: 37px 60px 48px;
        }
    }
}

@media (max-width: 768px) {
    .__app-modal-product-question {
        max-width: 100vw !important;
        width: 100% !important;

        .__app-product-question-modal-component {
            padding: 16px;
        }
    }
}

@media (min-width: 769px) {
    .__app-modal-product-question {
        width: 800px !important;
        max-width: 80vw !important;

        .__app-product-question-modal-component {
            padding: 37px 60px 48px;
        }
    }
}
