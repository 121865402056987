:root {
  --color-black: #253238;
  --color-gray: #eeeeee;
  --color-gray-light: #f5f5f5;
  --color-gray-dark: #aaaaaa;
  --color-gray-666: #666666;
  --color-gray-999: #999999;
  --color-gray-bdbdbd: #bdbdbd;
  --color-white: #ffffff;
  --color-primary: #1269FF;
  --color-primary-light: #F3F6FE;
  --color-primary-dark: #253238;
  --color-primary-disabled: #C2D0ED;
  --color-warn: #E72A2A;
  --color-warn-v2: #DE1E1E;
  --color-warn-light: #f6acac;
  --color-warn-dark: #C71F1F;
  --color-warning: #ffa000;
  --color-warning-light: #fdd835;
  --color-warning-dark: #b53d00;
  --color-success: #4caf50;
  --color-success-light: #c8e6c9;
  --color-success-dark: #1b5e20;
  --color-success-v2: #29A067;
  --color-border: #DCE2EF;
  --color-border-v2: #CAD0E1;
  --color-border-light: #F3F6FE;
  --color-border-dark: darken(#F3F6FE, 10%);
  --color-border-gray: #ddd;
  --color-border-gray-light: #eee;
  --color-border-gray-787878: #787878;
  --color-border-on-primary-light: #D5DDF4;
  --color-text-primary-dark: #9EACCA;
  --color-select-hover: #fffde7;
  --color-white-hover: rgba(255, 255, 255, 0.3);
  --color-black-backdrop: rgba(0, 0, 0, 0.7);
  --color-orange: #FB6321;
  --color-border-light-blue: #E5E8ED;
  --color-border-light-blue-v2: #E7ECF9;
  --color-border-light-blue-v3: #C3D4F1;
  --color-border-light-blue-v4: #E4E4E4;
  --color-border-light-blue-v5: #DEE3F0;
  --color-border-light-blue-v6: #BFC1C4;
  --color-border-light-blue-v7: #B2BED2;
  --color-border-light-blue-v8: #A0A8BE;
  --color-border-light-blue-v9: #DADFE8;
  --color-footer-border: var(--color-graphite7);
  --color-footer-header: white;
  --color-footer-link-color: var(--color-graphite4);
  --color-footer-link-color-hover: #FFFFFF;
  --color-footer-mobile-border: #223A6C;
  --color-footer-mobile-text: #9EACCA;
  --color-header-border-desktop: #E5E8ED;
  --color-white-on-primary: #DAE4FF;
  --color-subscribe-input-placeholder: #475578;
  --color-subscribe-input-disabled: #999999;
  --color-button-light-blue: #E2E9FC;
  --color-button-light-blue-active: #031B4E;
  --color-button-blue-active: #1269FF;
  --color-blue-gray-border: #C8CDDC;
  --color-blue-gray-light: #A7BDCC;
  --color-blue-gray: #9A9FAC;
  --color-blue-gray-dark: #6A7286;
  --color-blue-gray-background: #D8E0F4;
  --color-product-badge-sale: #E72A2A;
  --color-product-badge-discount: #E72A2A;
  --color-product-badge-new: #1DC775;
  --color-product-badge-hit: #FB6321;
  --color-product-badge-customer-choice: #FB6321;
  --color-product-badge-popular: #FB6321;
  --color-product-badge-out-of-production: #848B9E;
  --color-search-button: #566494;
  --color-progress-bar-bg: #CED1D8;
  --color-graphite4: #B0BFC6;
  --color-graphite6: #617E8C;
  --color-graphite7: #455B66;
  --color-graphite8: #253238;
}

:root {
  --color-box-shadow: rgba(5, 52, 116, 0.1);
  --color-box-shadow-v2: rgba(3, 27, 78, 0.1);
  --color-box-shadow-v3: rgba(0, 73, 255, 0.2);
  --color-box-shadow-v4: rgba(0, 73, 255, 0.1);
  --color-box-shadow-v5: rgba(0, 0, 0, 0.15);
  --color-box-shadow-mobile-header: rgba(0, 0, 0, 0.08);
}

:root {
  --linear-white: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
  --linear-white-90deg: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%);
  --linear-white-180deg: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%);
  --linear-white-270deg: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%);
}

.svg-fill-color-primary { fill: var(--color-primary); }
.svg-fill-color-primary-dark { fill: var(--color-primary-dark); }

.svg-stroke-color-primary { stroke: var(--color-primary); }
.svg-stroke-color-primary-dark { stroke: var(--color-primary-dark); }
